<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="row">
        <div class="col-lg-12 col-sm-12 text-center" style="margin-top: 20%">
          <div
            v-show="isLoader"
            class="text-center"
          >
            <img src="/img/loader.gif" />
          </div>
          <h1 v-show="!isLoader" style="color: #666">Access Denied</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: "AccessDenied",
        data() {
            return {
                isLoader: true
            };
        },
        mounted() {
            var $this = this;
            setTimeout(() => {
                $this.isLoader = false;
             }, 5000);
        },
        methods: {
        },
    };

</script>