<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Grievance</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Employee Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              disabled
                              placeholder="Enter Full Name"
                              v-model="detail.employee_name"
                              rules="required:Name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="name" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Department</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="department"
                              class="form-control"
                              disabled
                              placeholder="Enter Department"
                              v-model="detail.department"
                              rules="required:department"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage -->
                              <!-- name="department"
                              class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Designation</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="department"
                              class="form-control"
                              disabled
                              placeholder="Enter Designation"
                              v-model="detail.designation"
                              rules="required:designation"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage -->
                              <!-- name="designation"
                              class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>PF NO</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="pf_no"
                              class="form-control"
                              disabled
                              placeholder="Enter PF No"
                              v-model="detail.pf_no"
                              rules="required:pf no"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="pf_no" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>pf_no</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="pf_no"
                              class="form-control"
                              disabled
                              placeholder="Enter PF No"
                              v-model="detail.pf_no"
                              rules="required:pf no"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="pf_no" class="text-danger" /> -->
                          </div>
                        </div>
                         <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Father Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="father_name"
                              class="form-control"
                              disabled
                              placeholder="Enter Father Name"
                              v-model="detail.father_name"
                              rules="required:father name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="father_name" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Birth</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="date_of_birth"
                              class="form-control"
                              disabled
                              placeholder="Enter Date Of Birth"
                              v-model="detail.date_of_birth"
                              rules="required:date of birth"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="date_of_birth" class="text-danger" /> -->
                          </div>
                        </div>
                         <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Nominee Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Nominee Name"
                              class="form-control"
                              disabled
                              placeholder="Enter Nominee Name"
                              v-model="detail.nominee_name"
                              rules="required:Nominee Name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Nominee Name" class="text-danger" /> -->
                          </div>
                        </div>
                         <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>nominee_mobile</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="nominee_mobile"
                              class="form-control"
                              disabled
                              placeholder="Enter nominee_mobile"
                              v-model="detail.nominee_mobile"
                              rules="required:nominee_mobile"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="nominee_mobile" class="text-danger" /> -->
                          </div>
                        </div>
                          <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Nominee Mobile</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Nominee Mobile"
                              class="form-control"
                              disabled
                              placeholder="Enter Nominee Mobile"
                              v-model="detail.nominee_mobile"
                              rules="required:Nominee Mobile"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Nominee Mobile" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Relation Nominee</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Relation Nominee"
                              class="form-control"
                              disabled
                              placeholder="Enter Relation Nominee"
                              v-model="detail.relation_nominee"
                              rules="required:Relation Nominee"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Relation Nominee" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Landmark</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Landmark"
                              class="form-control"
                              disabled
                              placeholder="Enter Landmark"
                              v-model="detail.landmark"
                              rules="required:Landmark"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Landmark" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Address</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Address"
                              class="form-control"
                              disabled
                              placeholder="Enter Address"
                              v-model="detail.address"
                              rules="required:Address"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Address" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>City</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="City"
                              class="form-control"
                              disabled
                              placeholder="Enter City"
                              v-model="detail.city"
                              rules="required:City"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="City" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>State</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="State"
                              class="form-control"
                              disabled
                              placeholder="Enter State"
                              v-model="detail.State"
                              rules="required:State"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="State" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Pincode</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Pincode"
                              class="form-control"
                              disabled
                              placeholder="Enter Pincode"
                              v-model="detail.pincode"
                              rules="required:Pincode"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Pincode" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Pan Card</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Pan Card"
                              class="form-control"
                              disabled
                              placeholder="Enter Pan Card"
                              v-model="detail.pan_card"
                              rules="required:Pan Card"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Pan Card" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Mobile Number</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Mobile Number"
                              class="form-control"
                              disabled
                              placeholder="Enter Mobile Number"
                              v-model="detail.mobile_number"
                              rules="required:Mobile Number"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Mobile Number" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Email"
                              class="form-control"
                              disabled
                              placeholder="Enter Email"
                              v-model="detail.email"
                              rules="required:Email"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Email" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Demise</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Date Of Demise"
                              class="form-control"
                              disabled
                              placeholder="Enter Date Of Demise"
                              v-model="detail.date_of_demise"
                              rules="required:Date Of Demise"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Date Of Demise" class="text-danger" /> -->
                          </div>
                        </div>  <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Jonning</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Date Of Jonning"
                              class="form-control"
                              disabled
                              placeholder="Enter Date Of Jonning"
                              v-model="detail.date_of_jonning"
                              rules="required:Date Of Jonning"
                              :validateOnInput="true" 
                              />
                            <!-- <ErrorMessage name="Date Of Jonning" class="text-danger" /> -->
                          </div>
                        </div> 
                        <!-- <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button">
                            Cancel
                          </button>
                        </div> -->
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        password: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      user_type: "",
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/grievance/" + this.id,
        })
        .then((res) => {
          if (res && res.result) {
            this.detail = res.result;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
