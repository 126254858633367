<template>
  <div>
    <div class="wrapper">
      <div
        class="section-authentication-login d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12 col-lg-12 mx-auto">
            <div class="card radius-15">
              <div class="row no-gutters">
                <div class="col-lg-12 col-sm-12 my-auto">
                  <div class="card-body p-md-5">
                    <Form @submit="login">
                      <div class="text-center">
                        <img
                          style="height: 100px"
                          src="/images/logo-white.png"
                          width="200" />
                        <h5 style="font-weight: bold">Login</h5>
                      </div>
                      <div class="form-group">
                        <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                        <label>Username/Phone</label>
                        <Field
                          type="text"
                          class="form-control"
                          name="Username"
                          placeholder="Username"
                          v-model="details.username"
                          rules="required:username"
                          :validateOnInput="true" />
                        <ErrorMessage name="Username" class="text-danger" />
                      </div>
                      <div class="form-group">
                        <i class="fa fa-lock mr-1" aria-hidden="true"></i>
                        <label>Password</label>
                        <Field
                          type="password"
                          class="form-control"
                          name="_password"
                          placeholder="Password"
                          v-model="details.password"
                          rules="required:password"
                          :validateOnInput="true" />
                        <ErrorMessage name="_password" class="text-danger" />
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <!-- <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch1"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch1"
                            >Remember Me</label
                          >
                        </div> -->
                        </div>
                        <div class="form-group col text-right">
                          <router-link to="/forgot-password">
                            <a style="color: #5b67ca; font-weight: 500"
                              >Forgot Password?</a
                            >
                          </router-link>
                        </div>
                      </div>
                      <div class="btn-group mt-3 w-100">
                        <button
                          class="btn"
                          id="login-button"
                          style="color: #fff">
                          Log In
                        </button>
                      </div>
                      <!-- <router-link to="/signup">
                      <div class="text-center mt-4">
                        <p class="mb-0">
                          Don't have an account?
                          <a class="font-weight-bold">Sign up</a>
                        </p>
                      </div>
                    </router-link> -->
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      details: {
        username: "",
        password: "",
        role: "",
      },
    };
  },
  mounted() {},
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "/ouath-token",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res.result && res.result.role && res.result.role != "FIELD_INSPECTOR") {
            localStorage.setItem("role", res.result.role);
            this.$notify({
              type: "success",
              text: "Logged in successfully",
            });
            this.$router.push("/appointment-offcial");
          }else{
            this.$notify({
              type: "error",
              text: "You are not authorized to login",
            });
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    },
  },
};
</script>
