import { defineRule } from 'vee-validate';

defineRule('required', (value, [name]) => {
    if (name == 'annual income' && value !== "") {
        if (value >= 0) {
            return true
        }
        return "Please enter Ann. Income";
    }
    if (name == 'Groom' && value == "") {

        return "Please select Groom";
    }
    if (name == 'Bride' && value == "") {

        return "Please select Bride";
    }
    if (!value || value.length <= 0) {
        if (name) {
            return `Please enter ${name}`;
        }
        return 'This field is required';
    }
    return true;
});

defineRule('email', value => {
    if (value && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)) {
        return 'Please enter valid email';
    }
    return true;
});

defineRule('phone', value => {
    if (value && !/^[0-9]{10}$/.test(value)) {
        return 'Please enter valid contact number';
    }
    return true;
});

defineRule('minLength', (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule('maxLength', (value, [limit]) => {
    if (value.length > limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule('confirmed', (value, [target]) => {
    if (value === target) {
        return true;
    }
    return 'Passwords must match';
});

defineRule('price', value => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return 'Please enter valid price';
    }

    return true;
});

defineRule('numeric', value => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return 'Please enter numeric values only';
    }
    return true;
});

defineRule('instagram', value => {
    if (value && !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(value)) {
        return 'Please enter valid instagram url';
    }
    return true;
});

defineRule('facebook', value => {
    if (value && !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(value)) {
        return 'Please enter valid facebook url';

    }
    return true;
});

defineRule('linkedin', value => {
    if (value && !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(value)) {
        return 'Please enter valid linkedin url';

    }
    return true;
});

defineRule('youtube', value => {
    if (value && !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(value)) {
        return 'Please enter valid youtube url';

    }
    return true;
});
