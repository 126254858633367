<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Grievance</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Pension Amount</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="pension"
                              class="form-control"
                              placeholder="Enter pension"
                              v-model="detail.pension"
                              rules="required:pension amount"
                              @keypress="
                                $helperService.allowDecimalValue($event)
                              "
                              :validateOnInput="true" />
                            <ErrorMessage name="pension" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Gratuity Amount</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="gratuity"
                              class="form-control"
                              placeholder="Enter gratuity"
                              v-model="detail.graduity"
                              rules="required:gratuity amount"
                              @keypress="
                                $helperService.allowDecimalValue($event)
                              "
                              :validateOnInput="true" />
                            <ErrorMessage name="gratuity" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Insurence Amount</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Insurence"
                              class="form-control"
                              placeholder="Enter Insurence"
                              v-model="detail.insurence"
                              rules="required:insurence amount"
                              @keypress="
                                $helperService.allowDecimalValue($event)
                              "
                              :validateOnInput="true" />
                            <ErrorMessage
                              name="Insurence"
                              class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        password: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      user_type: "",
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/grievance-settlement/" + this.id,
        })
        .then((res) => {
          if (res && res.result) {
            this.detail = res.result;
          }
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.detail.gid = this.id;
        this.$api
          .putAPI({
            _action: "/update-griveance-settlement",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
            type: "success",
            text: res.message,
          });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
