<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto" :style="isAddStyle()">
        <slot></slot>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  data() {
    return {
      isShowModal: false,
      isPadding: false,
      isShowBigWidth: false,
    };
  },
  mounted() {},
  methods: {
    isAddStyle() {
      if (this.isShowBigWidth) {
        return "max-width: 1500px !important;width: 1105px;";
      } else if (this.isPadding) {
        return "padding: 0 !important;";
      }
    },
    showModal(isShow) {
      if (isShow) {
        this.isPadding = true;
      }
      this.isShowModal = true;
    },
    increaseModelWidth() {
      this.isShowBigWidth = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
